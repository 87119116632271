button {
  border-radius: 15px;
  background-image: linear-gradient(113deg, #1a6db4 -13%, #28aa99 108%);
  padding: 0px 60px;
  border: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 70px;
}

input {
  border: solid 1px #dbe3eb;
  border-radius: 10px;
  width: 98%;
  height: 60px;
  font-size: 20px !important;
  color: #788c9e;
  background-color: #fff;
  text-indent: 10px;
  padding: 0;
}

input:focus {
  outline: none;
  border: solid 1px #dbe3eb;
}

h2 {
  @media only screen and (max-width: 480px) {
    font-size: 1.5rem;
    margin-top: 30px;
  }
}

.header {
  height: 100px;
  width: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0 23px 99px 0 rgba(0, 0, 0, 0.09);
  z-index: 16;

  @media only screen and (max-width: 480px) {
    height: 78px;
  }

  .container {
    padding: 0px 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    @media only screen and (max-width: 480px) {
      padding: 0px;
      align-items: center;
      height: 78px;
      justify-content: center;
    }

    .install {
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      background-image: linear-gradient(98deg, #1a6db4 -12%, #28aa99 111%);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      @media only screen and (max-width: 480px) {
        display: none;
      }
    }

    .menu {
      display: flex;

      @media only screen and (max-width: 480px) {
        display: none;
      }
    }
  }
}

.home {
  background-color: #fff;

  @media only screen and (max-width: 480px) {
    flex-direction: row;
  }

  .container {
    display: flex;
    gap: 10%;
    align-items: flex-start;
    padding: 150px 200px 50px;
    flex-direction: row;


    @media only screen and (max-width: 480px) {
      padding: 15px;
      gap: 20px;
      text-align: justify;
      flex-direction: column;
      align-items: center;
    }

    .first-section {
      width: 50%;

      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      p {
        margin-top: 20px;

        @media only screen and (max-width: 480px) {
          margin-top: 20px;
        }
      }


      button {
        margin-top: 20px;

        @media only screen and (max-width: 480px) {
          margin: auto;
          margin-top: 20px;
        }
      }
    }

    .second-section {
      width: 50%;

      @media only screen and (max-width: 480px) {
        margin-top: 0px;
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}

.flow {
  background: #f6f8f8;

  .container {
    display: flex;
    gap: 10%;
    align-items: flex-start;
    padding: 150px 200px 50px;

    @media only screen and (max-width: 480px) {
      padding: 15px;
      flex-direction: column-reverse;
      gap: 20px;
    }

    &>.first-section {
      width: 50%;
      margin-top: -65px;

      @media only screen and (max-width: 480px) {
        width: 100%;
        margin-top: 0px;
      }

      img {
        width: 100%;
      }
    }

    .second-section {
      width: 50%;

      @media only screen and (max-width: 480px) {
        width: 100%;
      }

      .step-container {
        display: flex;
        gap: 30px;
        align-items: center;
        font-weight: 600;
        margin-top: 30px;
        cursor: pointer;

        @media only screen and (max-width: 480px) {
          gap: 15px;
        }

        .first-in-active {
          background: #e3e9ee;
          color: #788c9e;
        }

        .first-active {
          background: #28aa99;
          color: #fff;
        }

        .second-in-active {
          color: #788c9e;
        }

        .second-active {
          color: #28aa99;
        }

        .first-section {
          padding: 12px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;

          @media only screen and (max-width: 480px) {
            width: 28px;
            height: 20px;
          }
        }

        .second-section {
          font-size: 1.5rem;
          letter-spacing: 1px;

          @media only screen and (max-width: 480px) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.avatar {
  background: #fff;

  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 150px 200px;
    align-items: center;

    @media only screen and (max-width: 480px) {
      padding: 15px;
      align-items: flex-start;
      gap: 0px;
    }

    .first-section {
      margin-top: 60px;

      @media only screen and (max-width: 480px) {
        margin-top: 20px;
      }


      img {
        width: 100%;
      }
    }

    .second-section {
      margin-top: 20px;
    }

    button {
      margin-top: 35px;
      align-self: center;
    }
  }
}

.contact {
  background-color: #f6f8f8;

  .container {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    padding: 150px 200px 150px;
    justify-content: center;
    text-align: center;
    border-radius: 15px;

    @media only screen and (max-width: 480px) {
      padding: 15px;
    }

    .card {
      background: #fff;
      margin-top: 50px;
      width: 78vw;
      box-shadow: 0 22px 99px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #dbe3eb;
      border-radius: 15px;

      @media only screen and (max-width: 480px) {
        margin-top: 30px;
        width: 100%;
      }

      .first-section {
        display: flex;
        padding: 45px;
        justify-content: space-between;
        text-align: left;
        border-radius: 15px;
        gap: 61px;

        div {
          width: 50%;

          @media only screen and (max-width: 480px) {
            width: 100%;
          }
        }

        @media only screen and (max-width: 480px) {
          flex-direction: column;
          padding: 22px;
          gap: 35px
        }
      }

      .second-section {
        display: flex;
        padding: 0 45px 45px;
        justify-content: space-between;
        text-align: left;
        border-radius: 15px;
        gap: 61px;

        @media only screen and (max-width: 480px) {
          flex-direction: column;
          padding: 0px 22px;
          gap: 35px
        }

        div {
          width: 50%;

          @media only screen and (max-width: 480px) {
            width: 100%;
          }
        }
      }

      button {
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .copyright {
      text-align: center;
      margin-top: 100px;

      @media only screen and (max-width: 480px) {
        margin-top: 25px;
      }
    }
  }
}