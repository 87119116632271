@font-face {
  font-family: gilroy-Medium;
  src: url('./assets/fonts/Gilroy/Gilroy-Medium.ttf');
}

body * {
  font-family: gilroy-Medium !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

h2 {
  font-family: gilroy-Medium;
  font-size: 2.5rem;
  color: #39444b;
  letter-spacing: 1.5px;
  font-weight: 600;
  margin: 0px 0px 0px 0px;
}

p {
  font-family: gilroy-Medium;
  font-size: 1rem;
  color: #788c9e;
  font-weight: 600;
  margin: 0px 0px 0px 0px;
  line-height: 1.7;
  letter-spacing: 1.2px;
}